export function initializeCookieNotice() {
    const cookieNotice = document.getElementById('cookie-notice');
    const acceptButton = document.getElementById('accept-cookies');
    const saveButton = document.getElementById('save-preferences');
    const analyticsCheckbox = document.querySelector('input[name="analytics"]');
    const cookieSettingsLink = document.querySelector('a[href="/cookies/"], a[href="/en/cookies/"]');
  
    function setCookie(name, value, days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = "expires=" + date.toUTCString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }
  
    function getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
  
    function saveCookiePreferences(analytics) {
      localStorage.setItem('cookiePreferences', JSON.stringify({ analytics }));
      setCookie('cookieConsent', 'true', 365);
      cookieNotice.style.display = 'none';
      if (analytics) {
        initializeGoogleAnalytics();
      }
    }
  
    function loadCookiePreferences() {
      const preferences = JSON.parse(localStorage.getItem('cookiePreferences'));
      if (preferences) {
        analyticsCheckbox.checked = preferences.analytics;
        if (preferences.analytics) {
          initializeGoogleAnalytics();
        }
      }
    }
  
    function initializeGoogleAnalytics() {
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-VS6NR8MZ77');
    
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-VS6NR8MZ77';
        document.head.appendChild(script);
      }
  
    function showCookieNotice() {
      cookieNotice.style.display = 'block';
    }
  
    function hideCookieNotice() {
      cookieNotice.style.display = 'none';
    }
  
    function checkCookieConsent() {
      if (!getCookie('cookieConsent')) {
        showCookieNotice();
      } else {
        loadCookiePreferences();
      }
    }
  
    acceptButton.addEventListener('click', () => {
      saveCookiePreferences(true);
    });
  
    saveButton.addEventListener('click', () => {
      saveCookiePreferences(analyticsCheckbox.checked);
    });
  
    if (cookieSettingsLink) {
      cookieSettingsLink.addEventListener('click', (e) => {
        e.preventDefault();
        showCookieNotice();
      });
    }
  
    checkCookieConsent();
  }