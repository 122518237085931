export function initializeHeader() {
    const header = document.querySelector('.header');
    const headerHamburger = document.getElementById('headerHamburger');
    const mobileMenuHamburger = document.getElementById('mobileMenuHamburger');
    const mobileMenu = document.getElementById('mobileMenu');
    const navLinks = document.querySelectorAll('.header__nav-link, .link-ebilet');
    let isMenuOpen = false;
    let ticking = false;
    let isScrolling = false;
    let clickedNavItem = null;
    let isInitialLoad = true;

    const isHomePage = window.location.pathname === '/' || window.location.pathname === '/index.html' || window.location.pathname === '/en/' || window.location.pathname === '/en/index.html';

    function toggleMenu(show) {
        if (!headerHamburger || !mobileMenuHamburger || !mobileMenu) return;

        isMenuOpen = show;
        headerHamburger.querySelector('.hamburger').classList.toggle('active', show);
        mobileMenuHamburger.querySelector('.hamburger').classList.toggle('active', show);
        mobileMenu.classList.toggle('active', show);
        
        if (show) {
            document.body.style.overflow = 'hidden';
            mobileMenu.querySelector('.mobile-menu-content').style.opacity = '1';
            resetMenuItems(mobileMenu);
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
                startMenuItems(mobileMenu);
            }, 100);
        } else {
            mobileMenu.querySelector('.mobile-menu-content').style.opacity = '0';
            setTimeout(() => {
                document.body.style.overflow = '';
                resetMenuItems(mobileMenu);
            }, 50);
        }
    }

    function startMenuItems(menu) {
        menu.querySelectorAll('[class*="ani-"]').forEach(element => {
            element.classList.remove('ani--reset');
            element.classList.add('in-view');
        });
    }

    function resetMenuItems(menu) {
        menu.querySelectorAll('[class*="ani-"]').forEach(element => {
            element.classList.remove('in-view');
            element.classList.add('ani--reset');
        });
    }

    function setActiveNavItem() {
        if (!isHomePage) return;
        if (isScrolling && clickedNavItem) return;

        const scrollPosition = window.pageYOffset;
        const headerHeight = header ? header.offsetHeight : 0;
        const sections = document.querySelectorAll('section[id]');
        let currentSection = '';

        sections.forEach((section) => {
            const sectionTop = section.offsetTop - headerHeight - 10; // Add a small buffer
            const sectionBottom = sectionTop + section.clientHeight;
            
            if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
                currentSection = section.getAttribute('id');
            }
        });

        navLinks.forEach((link) => {
            const href = link.getAttribute('href');
            const hrefId = href.startsWith('/#') ? href.substring(2) : href.startsWith('#') ? href.substring(1) : href;
            
            if (hrefId === currentSection || href === window.location.pathname) {
                link.classList.add('header__nav-link--active');
            } else {
                link.classList.remove('header__nav-link--active');
            }
        });

        if (currentSection && currentSection !== window.location.hash.substring(1) && !isInitialLoad) {
            history.replaceState(null, null, `#${currentSection}`);
        }
    }

    function smoothScroll(targetId, clickedLink) {
        if (!isHomePage) return;

        const id = targetId.startsWith('/') ? targetId.substring(1) : targetId;
        const cleanId = id.startsWith('#') ? id.substring(1) : id;
        
        const targetElement = document.getElementById(cleanId);
        if (targetElement) {
            isScrolling = true;
            clickedNavItem = clickedLink;
            
            navLinks.forEach(link => link.classList.remove('header__nav-link--active'));
            clickedLink.classList.add('header__nav-link--active');

            const headerHeight = header ? header.offsetHeight : 0;
            const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - headerHeight;
            
            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth'
            });

            setTimeout(() => {
                isScrolling = false;
                clickedNavItem = null;
                setActiveNavItem();
                history.replaceState(null, null, `#${cleanId}`);
            }, 1000);
        }
    }

    function handleScroll() {
        if (header) {
            if (window.scrollY > 50) {
                header.classList.add('header--scrolled');
            } else {
                header.classList.remove('header--scrolled');
            }
        }

        if (isHomePage && !ticking) {
            window.requestAnimationFrame(() => {
                setActiveNavItem();
                ticking = false;
            });
            ticking = true;
        }
    }

    function handleResize() {
        if (window.innerWidth > 1520 && isMenuOpen) {
            toggleMenu(false);
        }
        if (isHomePage) {
            setActiveNavItem();
        }
    }

    function handleHashChange() {
        if (!isHomePage) return;

        const hash = window.location.hash;
        if (hash) {
            const cleanHash = hash.startsWith('#') ? hash.substring(1) : hash;
            const targetLink = Array.from(navLinks).find(link => {
                const href = link.getAttribute('href');
                return href.endsWith(cleanHash);
            });
            if (targetLink) {
                smoothScroll(cleanHash, targetLink);
            }
        }
        setActiveNavItem();
    }

    function handleNavClick(e) {
        const href = this.getAttribute('href');
        
        toggleMenu(false);

        if (!isHomePage && (href.startsWith('#') || href.startsWith('/#'))) {
            e.preventDefault();
            const cleanHref = href.replace(/^\/?(#|)/, '');
            sessionStorage.setItem('scrollToSection', cleanHref);
            window.location.href = '/';
            return;
        }

        if (!href.startsWith('#') && !href.startsWith('/#')) {
            return;
        }

        e.preventDefault();
        const targetId = href.startsWith('/#') ? href.substring(2) : href.startsWith('#') ? href.substring(1) : href;
        smoothScroll(targetId, this);

    }

    function scrollToSection(sectionId) {
        const targetElement = document.getElementById(sectionId);
        if (targetElement) {
            const headerHeight = header ? header.offsetHeight : 0;
            const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - headerHeight;
            
            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth'
            });

            setTimeout(() => {
                const newPosition = targetElement.getBoundingClientRect().top + window.scrollY - headerHeight;
                if (Math.abs(newPosition - targetPosition) > 50) {
                    // If the position has shifted significantly, scroll again
                    window.scrollTo({
                        top: newPosition,
                        behavior: 'smooth'
                    });
                }
                history.replaceState(null, null, `#${sectionId}`);
                setActiveNavItem();
            }, 250);

        }
    }

    function scrollToInitialHash() {
        if (!isHomePage) return;

        const hash = window.location.hash;
        if (hash) {
            const cleanHash = hash.substring(1);
            scrollToSection(cleanHash);
        }
    }

    function scrollToStoredSection() {
        const storedSection = sessionStorage.getItem('scrollToSection');
        if (storedSection) {
            sessionStorage.removeItem('scrollToSection');
            scrollToSection(storedSection);
        }
    }

    function init() {
        if (headerHamburger) {
            headerHamburger.addEventListener('click', () => toggleMenu(true));
        }

        if (mobileMenuHamburger) {
            mobileMenuHamburger.addEventListener('click', () => toggleMenu(false));
        }

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll, { passive: true });

        navLinks.forEach(anchor => {
            anchor.addEventListener('click', handleNavClick);
        });

        document.addEventListener('click', (event) => {
            if (mobileMenu && headerHamburger) {
                const isClickInsideMenu = mobileMenu.contains(event.target);
                const isClickOnHamburger = headerHamburger.contains(event.target);
                if (!isClickInsideMenu && !isClickOnHamburger && mobileMenu.classList.contains('active')) {
                    toggleMenu(false);
                }
            }
        });

        if (mobileMenu) {
            mobileMenu.addEventListener('click', (event) => {
                event.stopPropagation();
            });
        }

        if (isHomePage) {
            window.addEventListener('hashchange', handleHashChange);

            if (document.readyState === 'complete') {
                handleInitialScroll();
            } else {
                window.addEventListener('load', handleInitialScroll);
            }
        }

        handleScroll();
    }

    function handleInitialScroll() {
        if (window.location.hash) {
            setTimeout(scrollToInitialHash, 750);
        } else {
            scrollToStoredSection();
        }
        setActiveNavItem();
        isInitialLoad = false;
    }

    init();
}